class JobSelectLst {
  appFactory: any;
  $element:   any;
  $timeout:   any;

  search:         string;
  page:           number;
  pages:          number;
  preselected:    any;
  jobList:        any[];
  paginatedItems: any[];

  onSelect: Function;
  constructor ($element, $timeout, AppFactory) {
    Object.defineProperties(this, {
      $element:   { value: $element   },
      $timeout:   { value: $timeout   },
      appFactory: { value: AppFactory }
    });

    this.search = '';
    $timeout(() => this.updateList());
  }

  select(assignment): void {
    this.onSelect({ assignment });
    this.hide(true);
  }

  hide(preselected = false): void {
    this.$element.remove();
    if (!this.preselected && !preselected) this.appFactory.goBack();
  }

  updateList(page = null): void {
    this.page = page || this.page || 1;
    let filteredItems = this.jobList.filter(j => j.active).filter(j => j.title.toLowerCase().includes(this.search.toLowerCase()));
    this.paginatedItems = filteredItems.slice((this.page - 1) * 5, (this.page - 1) * 5 + 5);
    this.pages = Math.ceil(filteredItems.length / 5);
  }

}

window.app.component('jobSelectList', {
  template: require('scripts/components/time-trackings/job-select/job-select-list.html'),
  controller: ['$element', '$timeout', 'AppFactory', JobSelectLst],
  bindings: {
    jobList:     '<',
    selected:    '=',
    preselected: '<',
    onSelect:    '&'
  }
});

class JobSelect {
  $compile:            any;
  $scope:              any;
  $rootScope:          any;
  $location:           any;
  appFactory:          any;
  assignmentFactory:   any;
  timeTrackingFactory: any;
  expiredJobsService:  any;
  errFactory:          any;
  notification:        any;

  loaded:       boolean;
  assignments:  any[];
  jobList:      any[];
  skipEbsCheck: boolean;
  skipPopup:    boolean;
  ngModel:      any;
  ngChange:     Function;

  PRESELECTED_JOB: string = 'tt-preselected-job';
  constructor ($compile, $scope, $rootScope, $location, AppFactory, AssignmentFactory, TimeTrackingFactory, ExpiredJobsService, ErrFactory, Notification) {
    Object.defineProperties(this, {
      $compile:            { value: $compile            },
      $scope:              { value: $scope              },
      $rootScope:          { value: $rootScope          },
      $location:           { value: $location           },
      appFactory:          { value: AppFactory          },
      assignmentFactory:   { value: AssignmentFactory   },
      expiredJobsService:  { value: ExpiredJobsService  },
      timeTrackingFactory: { value: TimeTrackingFactory },
      errFactory:          { value: ErrFactory          },
      notification:        { value: Notification        }
    });

    this.loaded = false;
    Promise.resolve().then(() => {
      if (this.jobList) {
        this.assignments = this.jobList;
        if (!this.ngModel) this.checkPopup();
      } else { 
        $scope.$watch('$ctrl.ngModel', (newVal, oldVal) => {
          if (!this.loaded && (newVal || newVal === null)) this.loadJobSelect();
        }, true);
      }
    });
  }

  private loadJobSelect(): Promise<void> {
    this.loaded = true;
    return this.assignmentFactory.getOwn()
    .then(assignments => {
      this.assignments = assignments.filter(assignment => !this.expiredJobsService.isExpired(assignment.ends_at))
                                    .filter(assignment => assignment.starts_at < new Date)
                                    .filter(assignment => assignment.active !== false);

      if (this.assignments.length) return this.timeTrackingFactory.getOwn();
      if (!this.skipEbsCheck)      return Promise.reject(new this.errFactory.NoEBS);
    })
    .then(() => { if (!this.ngModel) this.checkPopup(); })
    .catch((err) => {
      if (err instanceof this.errFactory) err.notify();
      else console.error(err);
      this.appFactory.goBack();
    });
  }

  private checkPopup() {
    if (this.assignments.length === 1) {
      this.ngModel = this.assignments[0];
      this.ngChange({ assignment: this.assignments[0] });
      if (this.$rootScope.recentConfirmedJob) {
        let assignment = this.assignments.find((assignment) => assignment.id === this.$rootScope.recentConfirmedJob);
        this.$rootScope.recentConfirmedJob = null;
        if (!assignment) this.showConfirmJob();
      } else this.showConfirmJob();
    } else if (this.assignments.length > 1) {
      if (this.$rootScope.recentConfirmedJob) {
        let assignment = this.assignments.find((assignment) => assignment.id === this.$rootScope.recentConfirmedJob);
        this.$rootScope.recentConfirmedJob = null;
        if (assignment && assignment instanceof this.assignmentFactory) {
          this.ngModel = assignment;
          this.ngChange({ assignment });
        } else this.showSelect();
      } else if (!this.skipEbsCheck) {
        let assignment = this.getLastSelected();
        if (assignment && assignment instanceof this.assignmentFactory) {
          this.ngModel = assignment;
          this.ngChange({ assignment });
          this.showConfirmJob();
        } else this.showSelect();
      }
    }
    this.$scope.$apply();
  }

  private getLastSelected(): any {
    let peselectJobStr = localStorage.getItem(this.PRESELECTED_JOB),
        peselectJob    = peselectJobStr && JSON.parse(peselectJobStr);

    let endOfPreviousWeek = new Date;
    endOfPreviousWeek.setHours(23,59,59,999);
    endOfPreviousWeek = new Date(+endOfPreviousWeek - (endOfPreviousWeek.getDay() || 7)*24*60*60*1000);

    if (peselectJob && endOfPreviousWeek < peselectJob.at) {
      return this.assignments.find((assignment) => assignment.id === peselectJob.id);
    } else return null;
  }

  showConfirmJob(): void {
    if (!this.$location.path().includes('/time-tracking/') && !this.skipPopup) {
      let el = angular.element('<job-confirm options="$ctrl.assignments.length > 1" current="$ctrl.ngModel" on-show="$ctrl.showSelect()"></job-confirm>');
      angular.element(document).find('ng-view').append(this.$compile(el)(this.$scope));
    }
  }

  showSelect(): void {
    if (!this.skipPopup) {
      let el = angular.element("<job-select-list job-list='$ctrl.assignments' selected='$ctrl.ngModel' preselected='$ctrl.ngModel?true:false' on-select='$ctrl.select(assignment)'></job-select-list>");
      angular.element(document).find('ng-view').append(this.$compile(el)(this.$scope));
    }
  }

  select(assignment) {
    if (!assignment.confirmed && !this.skipEbsCheck) return Promise.resolve()
    .then(() => this.notification.confirm_p({
      title: 'note',
      desc: 'timeTrackings.ebsNotConfirmed',
      buttons: ['cancel','open']
    }))
    .then((res) => {
      if (res === 2) this.$location.path(`/ebs/${assignment.id}/max`);
      else {
        if (this.assignments.length > 1) this.showSelect();
        else this.$location.path('/');
        this.$scope.$apply();
      }
    });

    if (assignment instanceof this.assignmentFactory) {
      localStorage.setItem(this.PRESELECTED_JOB, JSON.stringify({id:assignment.id, at: Date.now()}));
    }
    this.ngModel = assignment;
    this.ngChange({ assignment });
    angular.element(document).find('job-confirm').remove();
  }
}

window.app.component('jobSelect', {
  template: require('scripts/components/time-trackings/job-select/job-select.html'),
  controller: ['$compile', '$scope', '$rootScope', '$location', 'AppFactory', 'AssignmentFactory', 'TimeTrackingFactory', 'ExpiredJobsService', 'ErrFactory', 'Notification', JobSelect],
  bindings: {
    jobList:      '<',
    skipEbsCheck: '<',
    skipPopup:    '<',
    ngModel:      '=',
    ngChange:     '&'
  }
});
